import { createAction, props } from '@ngrx/store';
import { LoggedInModuleType } from '../../../shared/interfaces/logged-in-module.type';
import { User } from '../../../shared/interfaces/user.type';
import { HttpErrorResponse } from '@angular/common/http';


export const login = createAction('[Authentication] Login',
  props<{ email: string; password: string }>()
);

export const loginSuccess = createAction('[Authentication] Login Success',
  props<{ accessToken: string, refreshToken: string, user: User, loggedInModule: LoggedInModuleType }>()
);

export const loginAreaChooseDialog = createAction('[Authentication] Login Area Choose Dialog',
  props<{ accessToken: string, refreshToken: string, user: User, roles: LoggedInModuleType[]; }>()
);

export const refreshTokenInProgress = createAction('[Authentication] Refresh Token In Progress',
  props<{ isAuthenticated: boolean }>()
);

export const refreshTokenSuccess = createAction('[Authentication] Refresh Token Success',
  props<{ accessToken: string, refreshToken: string, user: User, roles: LoggedInModuleType[]; }>()
);

export const loginFailure = createAction('[Authentication] Login Failure',
  props<{ error: HttpErrorResponse }>()
);

export const logout = createAction('[Authentication] Logout');

export const unAuthorized = createAction('[Authentication] UnAuthorized');

export const updateUser = createAction('[Authentication] Update Logged In User',
  props<{ user: User }>()
);


export const noAction = createAction('[Authentication] No Action');