import { Injectable } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { LoginResponse } from '../modules/shared/interfaces/login-response.type';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import * as AuthActions from '../modules/core/store/actions/auth.actions';
import * as fromApp from '../modules/core/store/app.state';
import * as fromAuth from '../modules/core/store/reducers/auth.reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected http: HttpClient, private store: Store<fromApp.AppState>) { }


  isLoggedIn() {
    const token = sessionStorage.getItem('accessToken');
    return token != null;
  }

  login(email: string, password: string): Observable<any> {
    // this is a mocked response to be able to test the example
    // console.log('Email:', email, 'Password:', password);
    const body = JSON.stringify({ userName: email, password, })// apiKey: this.appEnv.getConfig().apiKey });

    return this.http.post<LoginResponse>('/entrance/login', body);
  }

  logout() {
    console.log('**************LogOut Called**********************');
    // let body = JSON.stringify({ id: 0 });
    // this.http.post(`/entrance/logout`, body).subscribe(r => {});

    this.store.dispatch(AuthActions.logout());
  }

  refreshAccessToken(): Observable<any> {
    let body = JSON.stringify({ refreshToken: '' });
    return this.store.select('auth').pipe(
      take(1),
      switchMap((authState: fromAuth.State) => {
        if (authState && authState.refreshToken) {
          body = JSON.stringify({ refreshToken: authState.refreshToken });
          return this.http.post<LoginResponse>('/entrance/refresh-login-token', body);
        } else {
          return this.http.post<LoginResponse>('/entrance/refresh-login-token', { refreshToken: '' });
        }
      })
    );
  }

  
  verifyUser(data: any): Observable<any> {
    return this.http.post<LoginResponse>('/api/v1/user/verify-and-update', data);
  }

  recoverPassword(data: any): Observable<any> {
    return this.http.post('/api/v1/entrance/recover-password', data);
  }

  verifyForgotPassword(data: any): Observable<any> {
    return this.http.post('/api/v1/entrance/verify-update-password', data);
  }

  updateProfile(data: any): Observable<any> {
    return this.http.post('/api/v1/user/update-profile', data);
  }

  /*FORGOT PASSWORD */
  forgotPassword(data: any): Observable<any> {
    return this.http.post('/signup/forgot-password', JSON.stringify(data))
  }

  /*RESET PASSWORD */
  resetPassword(data: any): Observable<any> {
    return this.http.post('/signup/reset-password', JSON.stringify(data))
  }

  /*CHANGE PASSWORD */
  changePassword(data: any): Observable<any> {
    return this.http.post(`/entrance/change-password`, data);
  }


  authorizeLineAccount(data: any): Observable<any> {
    return this.http.post(`/entrance/authorize-line-account`, data)
  }

  authorizeGoogleAccount(data: any): Observable<any> {
    return this.http.post(`/entrance/authorize-google-account`, data);
  }

  removeLineAccount(): Observable<any> {
    return this.http.get(`/entrance/remove-line-account`);
  }

  removeGoogleAccount(): Observable<any> {
    return this.http.delete(`/entrance/remove-google-account`);
  }

}
